








































import {Component, Vue} from 'vue-property-decorator';

@Component
export default class UiRadioButtons extends Vue {
  private radioGroup = 'radio-1';
  private radioGroup2 = 'radio-2';
}
