























































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class UiTextareas extends Vue {
  private text: string = 'Lorem ipsum';
  private empty: string = '';
}
