


























import {Component, Vue} from 'vue-property-decorator';

@Component
export default class UiCalendar extends Vue {
  private date: string = '';
  private date1: string = '';
}
