






































import {Component, Vue} from 'vue-property-decorator';

@Component
export default class UiCheckboxes extends Vue {
  private checkbox1: boolean = false;
  private checkbox2: boolean = true;
  private checkbox3: boolean = true;
  private checkbox4: boolean = false;
}
