var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"ui-carousel"}},[_c('h2',{staticClass:"text-h2 mb-10"},[_vm._v("Carousel")]),_c('v-row',[_c('v-col',[_c('table',[_c('tr',{staticClass:"slides"},[_c('td',[_vm._v("Default")]),_c('td',[_c('Carousel',{attrs:{"itemsList":_vm.itemsList},scopedSlots:_vm._u([{key:"slide",fn:function(ref){
var slide = ref.slide;
return [_c('div',{staticClass:"asad"},[_vm._v(_vm._s(slide))])]}}])})],1)]),_c('tr',{staticClass:"slides"},[_c('td',[_vm._v("Wirt arrows")]),_c('td',[_c('Carousel',{attrs:{"itemsList":_vm.itemsList,"allowArrows":true},scopedSlots:_vm._u([{key:"slide",fn:function(ref){
var slide = ref.slide;
return [_c('div',{staticClass:"asad"},[_vm._v(_vm._s(slide))])]}}])})],1)]),_c('tr',{staticClass:"slides"},[_c('td',[_vm._v("Without pagination")]),_c('td',[_c('Carousel',{attrs:{"itemsList":_vm.itemsList,"allowPagination":false},scopedSlots:_vm._u([{key:"slide",fn:function(ref){
var slide = ref.slide;
return [_c('div',{staticClass:"asad"},[_vm._v(_vm._s(slide))])]}}])})],1)]),_c('tr',{staticClass:"slides"},[_c('td',[_vm._v("With breakpoints")]),_c('td',[_c('Carousel',{attrs:{"itemsList":_vm.itemsList,"breakpoints":_vm.breakpoints},scopedSlots:_vm._u([{key:"slide",fn:function(ref){
var slide = ref.slide;
return [_c('div',{staticClass:"asad"},[_vm._v(_vm._s(slide))])]}}])})],1)]),_c('tr',[_c('td',[_vm._v("Policies slides")]),_c('td',[_c('Carousel',{attrs:{"itemsList":_vm.itemsList,"type":"policiesCarousel","allowArrows":true,"breakpoints":{320: {slidesPerView: 1, spaceBetween: 30, allowTouchMove: false}}},scopedSlots:_vm._u([{key:"slide",fn:function(ref){
var slide = ref.slide;
return [_c('PolicyCard',{attrs:{"cardInfo":_vm.cardInfo}})]}}])})],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }