



















import {Component, Vue} from 'vue-property-decorator';

@Component
export default class UiTables extends Vue {
  private headers = [
    { text: this.$t('table.status'), value: 'status' },
    { text: this.$t('table.date_of_loss'), value: 'date' },
    { text: this.$t('table.address'), value: 'address' },
    { text: this.$t('table.landlord'), value: 'landlord' },
    { text: this.$t('table.amount_claim'), value: 'amount', align: 'right'},
    { text: this.$t('table.amount_charged'), value: 'charged', align: 'right' },
    { text: '', value: 'link', class: 'link', cellClass: 'link'},
  ];

  private headers1 = [
    { text: this.$t('table.status'), value: 'status' },
    { text: this.$t('table.date_of_loss'), value: 'date' },
    { text: this.$t('table.address'), value: 'address' },
    { text: this.$t('table.landlord'), value: 'landlord' },
    { text: this.$t('table.amount_claim'), value: 'amount', align: 'right'},
    { text: '', value: 'arrow', class: 'link', cellClass: 'link'},
  ];

  private headers2 = [
    { text: this.$t('table.status'), value: 'status' },
    { text: this.$t('table.date_of_loss'), value: 'date' },
    { text: this.$t('table.address'), value: 'address' },
    { text: this.$t('table.landlord'), value: 'landlord' },
    { text: this.$t('table.amount_claim'), value: 'amount', align: 'right'},
    { text: '', value: 'more', class: 'link', cellClass: 'link'},
  ];

  private claims = [
    {
      id: '97723b20-2bc0-4ab8-9db8-0c8ad05da91e',
      status: 'IN_PROGRESS',
      date: '2021-08-05T14:59:12',
      type: 'EXCESSIVE_DAMAGE',
      amount: 1500,
      currency: 'EUR',
      description: 'Furniture, doors and walls were damaged',
      justificationMessage: 'We confirmed the claim',
      damagePlace: 'OTHER',
      damageTypes: [
        'DOORS',
        'FURNITURE',
        'WALLS'
      ],
      tenantDocuments: [],
      address: 'Lugovaya 1, 010101 Kyiv, UA',
      landlord: 'John Doe',
      charged: 1000,
    },
    {
      id: '97723b20-2bc0-4ab8-9d1b8-0c8ad05da91e',
      status: 'RESOLVED',
      date: '2021-08-05T14:59:12',
      type: 'EXCESSIVE_DAMAGE',
      amount: 1500,
      currency: 'USD',
      description: 'Furniture, doors and walls were damaged',
      justificationMessage: 'We confirmed the claim',
      damagePlace: 'OTHER',
      damageTypes: [
        'DOORS',
        'FURNITURE',
        'WALLS'
      ],
      tenantDocuments: [],
      address: 'Lugovaya 1, 010101 Kyiv, UA',
      landlord: 'John Doe',
      charged: 0,
      due_date: '2021-08-05T14:59:12'
    },
    {
      id: '97723b20-2bc0-4ab8-9db8-0c8ad05da291e',
      status: 'CANCELED',
      date: '2021-08-05T14:59:12',
      type: 'EXCESSIVE_DAMAGE',
      amount: 1500,
      currency: 'GBP',
      description: 'Furniture, doors and walls were damaged',
      justificationMessage: 'We confirmed the claim',
      damagePlace: 'OTHER',
      damageTypes: [
        'DOORS',
        'FURNITURE',
        'WALLS'
      ],
      tenantDocuments: [],
      address: 'Lugovaya 1, 010101 Kyiv, UA',
      landlord: 'John Doe',
      charged: 0,
    },
  ];
}
