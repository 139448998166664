
















import {Component, Vue} from 'vue-property-decorator';

@Component
export default class UiRating extends Vue {
  private rating: number = 3;
}
