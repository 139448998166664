



















import { Component, Vue } from 'vue-property-decorator';
import Navigation from '@/components/Layout/Navigation.vue';

@Component({
  components: {Navigation}
})
export default class UiDrawers extends Vue {
  private showDrawer: boolean = false;
}
