






























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class UiNotifications extends Vue {
  private showNotification(type) {
    this.$notify({
      group: 'notification',
      type: `notification-${type}`,
      text: 'Text of notification',
      title: 'Title'
    });
  }
}
