






































































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class UiSelects extends Vue {
  private singleValue = '';
  private multipleValue = [];
  private multipleObjectsSingleValue = '';
  private multipleObjectsMultipleValue = [];
  private items = ['test1', 'test2', 'test3', 'test4'];
  private itemsObjects = [
    {
      name: 'test object 1',
      value: 'test1'
    },
    {
      name: 'test object 2',
      value: 'test2'
    },
    {
      name: 'test object 2',
      value: 'test2'
    },
    {
      name: 'test object 2',
      value: 'test2'
    },
  ];
}
