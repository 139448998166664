

















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class UiColors extends Vue {
  public colorList = [
    'primary',
    'secondary',
    'tertiary',
    'success',
    'error',
    'warning',
    'info',
  ];
}
