

























































import { Component, Vue } from 'vue-property-decorator';
import PolicyCard from '@/components/PolicyCard.vue';

@Component({
  components: {
    PolicyCard
  }
})
export default class UiCarousel extends Vue {
  private cardInfo = {
    address: {
      street: 'Lugovaya',
      streetNumber: '1',
      zip: '010101',
      city: 'Kyiv',
      country: 'UA'
    },
    city: 'Kyiv',
    country: 'UA',
    street: 'Lugovaya',
    streetNumber: '1',
    zip: '010101',
    claimCharged: 0,
    depositCoverage: 10300,
    id: '182c8882-d886-48f3-864b-bde08ee727fc',
    name: 'Deposit Insurance Plan',
  };
  private breakpoints = {
    1024: {
      slidesPerView: 2,
      spaceBetween: 30
    },
    768: {
      slidesPerView: 1,
      spaceBetween: 30
    },
    320: {
      slidesPerView: 1,
      spaceBetween: 30
    }
  };

  private itemsList = [
    1,
    2,
    3,
    4
  ];
}
