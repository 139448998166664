































import {Vue, Component, Prop} from 'vue-property-decorator';
import {IPolicyItem} from '@/interfaces';

@Component
export default class PolicyCard extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  private cardInfo!: IPolicyItem;
}
