














import {Component, Vue} from 'vue-property-decorator';
import Uploader from '@/components/Ui/Uploader/Uploader.vue';
import PolicyApiService from '@/services/api/policy.service';

@Component({
  components: { Uploader }
})
export default class UiUploader extends Vue {
  // Identifier must be policy or claim
  public id = '';

  // Already uploaded attachments
  public attachments = [];

  public upload(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('id', this.id);
    formData.append('origin', 'POLICY');
    // if (origin === POLICY) add type
    formData.append('type', 'TENANT_ATTACHMENTS');

    return PolicyApiService.uploadFile(formData);
  }

  public remove(documentId: string) {
    return PolicyApiService.removeFile({
      id: this.id,
      documentId
    });
  }
}
