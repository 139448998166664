














import { VueConstructor } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import UiTypography from '@/pages/Ui/Ui.typography.vue';
import UiColors from '@/pages/Ui/Ui.colors.vue';
import UiButtons from '@/pages/Ui/Ui.buttons.vue';
import UiInputs from '@/pages/Ui/Ui.inputs.vue';
import UiTextareas from '@/pages/Ui/Ui.textAreas.vue';
import UiModals from '@/pages/Ui/Ui.modals.vue';
import UiLinks from '@/pages/Ui/Ui.links.vue';
import UiCheckboxes from '@/pages/Ui/Ui.checkboxes.vue';
import UiRadioButtons from '@/pages/Ui/Ui.radioButtons.vue';
import UiSelects from '@/pages/Ui/Ui.selects.vue';
import UiDrawers from '@/pages/Ui/Ui.drawers.vue';
import UiAccordions from '@/pages/Ui/Ui.accordions.vue';
import UiCalendar from '@/pages/Ui/Ui.calendar.vue';
import UiCarousel from '@/pages/Ui/Ui.carousel.vue';
import UiTables from '@/pages/Ui/Ui.tables.vue';
import UiUploader from '@/pages/Ui/Ui.uploader.vue';
import UiNewPolicy from '@/pages/Ui/Ui.newPolicy.vue';
import UiSection from '@/pages/Ui/Ui.Section.vue';
import UiRating from '@/pages/Ui/Ui.rating.vue';
import UiStatisticCards from '@/pages/Ui/Ui.statisticCards.vue';
import UiChip from '@/pages/Ui/Ui.chip.vue';
import UiNotifications from '@/pages/Ui/Ui.notifications.vue';

export interface IUIComponent {
  title: string;
  component: VueConstructor;
  hash: string;
  active: boolean;
}

export const listItem: IUIComponent[] = [
  {
    title: 'Colors',
    component: UiColors,
    hash: 'ui-colors',
    active: false
  },
  {
    title: 'Typography',
    component: UiTypography,
    hash: 'ui-typography',
    active: false
  },
  {
    title: 'Buttons',
    component: UiButtons,
    hash: 'ui-buttons',
    active: false
  },
  {
    title: 'Inputs',
    component: UiInputs,
    hash: 'ui-inputs',
    active: false
  },
  {
    title: 'Modals',
    component: UiModals,
    hash: 'ui-modals',
    active: false
  },
  {
    title: 'Links',
    component: UiLinks,
    hash: 'ui-links',
    active: false
  },
  {
    title: 'Checkboxes',
    component: UiCheckboxes,
    hash: 'ui-checkboxes',
    active: false
  },
  {
    title: 'Radio',
    component: UiRadioButtons,
    hash: 'ui-radioButtons',
    active: false
  },
  {
    title: 'Selects',
    component: UiSelects,
    hash: 'ui-selects',
    active: false
  },
  {
    title: 'Drawers',
    component: UiDrawers,
    hash: 'ui-drawers',
    active: false
  },
  {
    title: 'Textareas',
    component: UiTextareas,
    hash: 'ui-textAreas',
    active: false
  },
  {
    title: 'Accordions',
    component: UiAccordions,
    hash: 'ui-accordions',
    active: false
  },
  {
    title: 'Calendar',
    component: UiCalendar,
    hash: 'ui-calendar',
    active: false
  },
  {
    title: 'Carousel',
    component: UiCarousel,
    hash: 'ui-carousel',
    active: false
  },
  {
    title: 'Tables',
    component: UiTables,
    hash: 'ui-tables',
    active: false
  },
  {
    title: 'Uploader',
    component: UiUploader,
    hash: 'ui-uploader',
    active: false
  },
  {
    title: 'New policy',
    component: UiNewPolicy,
    hash: 'ui-new_policy',
    active: false
  },
  {
    title: 'Section',
    component: UiSection,
    hash: 'ui-sections',
    active: false
  },
  {
    title: 'Ratings',
    component: UiRating,
    hash: 'ui-ratings',
    active: false
  },
  {
    title: 'Statistic cards',
    component: UiStatisticCards,
    hash: 'ui-statistic-cards',
    active: false
  },
  {
    title: 'Chip',
    component: UiChip,
    hash: 'ui-chip',
    active: false
  },
  {
    title: 'Notifications',
    component: UiNotifications,
    hash: 'ui-notifications',
    active: false
  },
];

@Component({
  components: {
    UiTypography,
    UiColors,
    UiButtons,
    UiInputs,
    UiModals,
    UiLinks,
    UiCheckboxes,
    UiRadioButtons,
    UiSelects,
    UiDrawers,
    UiTextareas,
    UiAccordions,
    UiCalendar,
    UiCarousel,
    UiTables,
    UiUploader,
    UiNewPolicy,
    UiSection,
    UiRating,
    UiStatisticCards,
    UiChip,
    UiNotifications,
  }
})
export default class UiPage extends Vue {
  public listItem: IUIComponent[] = listItem;
  public get activeComponentsList(): IUIComponent[] {
    return this.listItem.filter((component) => component.active);
  }

  public visibilityChanged(isVisible: boolean, entry: IntersectionObserverEntry) {
    const id = entry.target.getAttribute('id');
    this.listItem.forEach((component) => {
      if (component.hash === id) {
        isVisible ? component.active = true : component.active = false;
      }
    });
  }
}
