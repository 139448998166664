



































import {Component, Vue} from 'vue-property-decorator';

@Component
export default class UiSection extends Vue {
  private title: string = 'Lorem title';
  private description1: string = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, voluptas.';
  private description2: string = 'Lorem ipsum dolor.';
  private description3: string = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquam aliquid architecto cumque cupiditate deleniti deserunt dignissimos distinctio earum enim eveniet exercitationem expedita explicabo facilis fuga harum in ipsum iste labore nesciunt numquam odit optio provident qui rem similique, vitae.';
}
